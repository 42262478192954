import React, { useEffect } from 'react';
import { Menu, Dropdown, Avatar, message } from 'antd';
import { connect } from 'react-redux';
import {
  EditOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  // HourglassOutlined,
  // BulbOutlined,
  CarOutlined,
  // PictureOutlined,
  // CalendarOutlined,
  // Modification start - 12.12.2024 - olain-tarcina-sc-716
  PictureOutlined,
  // Modification end - 12.12.2024 - olain-tarcina-sc-716
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { logout } from '../../actions/auth';
import { getProfileDetailsByUserID } from '../../actions/profile';
import PropTypes from 'prop-types';

/**
 * olain-tarcina-sc-716: YMPLITE changes
 *
 */

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/editprofile`,
    key: 'editprofile',
  },
  {
    title: 'My Vessel',
    icon: CarOutlined,
    path: `${APP_PREFIX_PATH}/myvessel`,
    key: 'myvessel',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: `${APP_PREFIX_PATH}/help`,
    key: 'help',
  },
];

export const NavProfile = ({
  auth: { user, loading },
  logout,
  isMobile,
  boat: { boatdetails },
  profile: { profileDetails },
  getProfileDetailsByUserID,
}) => {
  useEffect(() => {
    const userID = localStorage.getItem('userID');
    getProfileDetailsByUserID(userID);

    // eslint-disable-next-line
  }, []);

  const profileImg = user && user.profilePhoto;
  const profileName = profileDetails?.firstName;
  const jobTitle = user && user.jobtitle;
  const userID = user && user._id;
  const free = boatdetails && boatdetails.free;
  const superadmin = user && user.superadmin;
  const role = user && user.role;

  const changePassword = user?.changePassword;

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{profileName}</h4>
            <span className="text-muted">
              {role === 'management' ? 'Management' : jobTitle}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {free !== 'EXPIRED' || superadmin
            ? menuItem.map((el, i) =>
                el.title === 'Edit Profile' ? (
                  changePassword ? null : (
                    <Menu.Item key={i}>
                      <a href={el.path + '/' + userID}>
                        <Icon className="mr-3" type={el.icon} />
                        <span className="font-weight-normal">{el.title}</span>
                      </a>
                    </Menu.Item>
                  )
                ) : (
                  <Menu.Item key={i}>
                    <a href={el.path}>
                      <Icon className="mr-3" type={el.icon} />
                      <span className="font-weight-normal">{el.title}</span>
                    </a>
                  </Menu.Item>
                )
              )
            : null}

          {/* // Modification start - 12.12.2024 - olain-tarcina-sc-716 */}
          {superadmin === undefined ? null : changePassword ? null : (
            <Menu.Item key={menuItem.length + 1}>
              <a href={`${APP_PREFIX_PATH}/equipmentphotos`}>
                <Icon className="mr-3" type={PictureOutlined} />
                <span className="font-weight-normal">Equipment Photos</span>
              </a>
            </Menu.Item>
          )}
          {/* // Modification end - 12.12.2024 - olain-tarcina-sc-716 */}

          <Menu.Item key={menuItem.length + 2} onClick={(e) => handleLogout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  const handleLogout = () => {
    message.destroy();
    logout();
  };

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
          {!isMobile ? (
            <span className="text-primary">
              {' '}
              {profileName ? 'Hi, ' + profileName : null}
            </span>
          ) : null}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavProfile.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // modification start - 08.20.2022 - ymploki-157
  boat: PropTypes.object.isRequired,
  // modification end - 08.20.2022 - ymploki-157
  profile: PropTypes.object.isRequired,
  getProfileDetailsByUserID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // modification start - 08.20.2022 - ymploki-157
  boat: state.boat,
  // modification end - 08.20.2022 - ymploki-157
  profile: state.profile,
});

export default connect(mapStateToProps, { logout, getProfileDetailsByUserID })(
  NavProfile
);
