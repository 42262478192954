import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid, Badge } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';

/**
 * olain-tarcina-sc-681
 */

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    user,
    dueTotal,
    free,
    navCollapsed,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const superadmin = user && user.superadmin;

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {free !== 'EXPIRED' || superadmin
        ? navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
              <Menu.ItemGroup
                key={menu.key}
                title={setLocale(localization, menu.title)}
              >
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuSecond.path}
                          />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst.icon} />
                      ) : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuFirst.path}
                      />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : (
              sidemenuSwitch(
                menu,
                localization,
                user,
                dueTotal,
                closeMobileNav,
                navCollapsed,
                isMobile
              )
            )
          )
        : null}
    </Menu>
  );
};

const TopNavContent = (props) => {
  //const { topNavColor, localization } = props;
  const { topNavColor, localization, user, dueTotal, free } = props;

  const superadmin = user && user.superadmin;

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {free !== 'EXPIRED' || superadmin
        ? navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
              <SubMenu
                key={menu.key}
                popupClassName="top-nav-menu"
                title={
                  <span>
                    {menu.icon ? <Icon type={menu?.icon} /> : null}
                    <span>{setLocale(localization, menu.title)}</span>
                  </span>
                }
              >
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      key={subMenuFirst.key}
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link to={subMenuSecond.path} />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            ) : (
              menuSwitch(menu, localization, user, dueTotal)
            )
          )
        : null}
    </Menu>
  );
};

const menuSwitch = (menu, localization, user, dueTotal) => {
  const jobs = user && user.jobs;
  const logbook = user && user.logbook;
  const inventory = user && user.inventory;
  const employees = user && user.employees;
  const superadmin = user && user.superadmin;
  const watchkeeping = user && user.watchkeeping;
  const reports = user && user.reports;
  const supplier = user && user.supplier;
  const equipmentspage = user && user.equipmentspage;
  const documents = user && user.documents;
  const watchkeeper = user && user.watchkeeper;
  const ism = user && user.ism;
  const guests = user && user.guests;
  const hourstracker = user && user.hourstracker;
  const spareparts = user && user.spareparts;
  const shipyard = user && user.shipyard;
  // const accounting = user && user.accounting;
  const sales = user && user.sales;
  const calendar = user && user.calendar;
  const management = user && user.management;

  switch (menu.key) {
    case 'jobs':
      return jobs ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
          <Badge count={dueTotal}>
            <span style={{ marginLeft: '16px' }}> </span>
          </Badge>
        </Menu.Item>
      ) : null;
    case 'management':
      return management ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'inventory':
      return inventory ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'logbook':
      return logbook ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'equipmentspage':
      return equipmentspage ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'employees':
      return employees ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'watchkeeping':
    case 'watchkeeper':
      return watchkeeping || watchkeeper ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'reports':
      return reports ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'supplier':
      return supplier ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'documents':
      return documents ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'ism':
      return ism ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'guests':
      return guests ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'hourstracker':
      return hourstracker ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'spareparts':
      return spareparts ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    // case 'accounting':
    //   return accounting ? (
    //     <Menu.Item key={menu.key}>
    //       {menu.icon ? (
    //         <Icon type={menu?.icon} className="text-warning" />
    //       ) : null}
    //       <span className="text-primary">
    //         {setLocale(localization, menu?.title)}
    //       </span>
    //       {menu.path ? <Link to={menu.path} /> : null}
    //     </Menu.Item>
    //   ) : null;
    case 'sales':
      return sales ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'shipyard':
      return shipyard ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'calendar':
      return calendar ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'superadmin':
      return superadmin ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    case 'litesuperadmin':
      return superadmin ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? (
            <Icon type={menu?.icon} className="text-warning" />
          ) : null}
          <span className="text-primary">
            {setLocale(localization, menu?.title)}
          </span>
          {menu.path ? <Link to={menu.path} /> : null}
        </Menu.Item>
      ) : null;
    default:
      return null;
  }
};

const sidemenuSwitch = (
  menu,
  localization,
  user,
  dueTotal,
  closeMobileNav,
  navCollapsed,
  isMobile
) => {
  const jobs = user && user.jobs;
  const logbook = user && user.logbook;
  const inventory = user && user.inventory;
  const employees = user && user.employees;
  const superadmin = user && user.superadmin;
  const watchkeeping = user && user.watchkeeping;
  const reports = user && user.reports;
  const supplier = user && user.supplier;
  const equipmentspage = user && user.equipmentspage;
  const documents = user && user.documents;
  const watchkeeper = user && user.watchkeeper;
  const ism = user && user.ism;
  const guests = user && user.guests;
  const hourstracker = user && user.hourstracker;
  const spareparts = user && user.spareparts;
  const shipyard = user && user.shipyard;
  // const accounting = user && user.accounting;
  const sales = user && user.sales;
  const calendar = user && user.calendar;
  const management = user && user.management;

  switch (menu.key) {
    case 'jobs':
      return jobs ? (
        <Menu.Item key={menu.key}>
          <Badge count={dueTotal}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
          </Badge>
          {!navCollapsed || isMobile ? (
            <span>{setLocale(localization, menu?.title)}</span>
          ) : null}
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'management':
      return management ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'inventory':
      return inventory ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'logbook':
      return logbook ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'equipmentspage':
      return equipmentspage ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'employees':
      return employees ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;

    case 'watchkeeping':
      return watchkeeping ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'reports':
      return reports ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'supplier':
      return supplier ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'documents':
      return documents ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'watchkeeper':
      return watchkeeper ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'ism':
      return ism ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'guests':
      return guests ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'hourstracker':
      return hourstracker ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'spareparts':
      return spareparts ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    // case 'accounting':
    //   return accounting ? (
    //     <Menu.Item key={menu.key}>
    //       {menu.icon ? <Icon type={menu?.icon} /> : null}
    //       <span>{setLocale(localization, menu?.title)}</span>
    //       {menu.path ? (
    //         <Link onClick={() => closeMobileNav()} to={menu.path} />
    //       ) : null}
    //     </Menu.Item>
    //   ) : null;
    case 'sales':
      return sales ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'shipyard':
      return shipyard ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'calendar':
      return calendar ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    case 'litesuperadmin':
      return superadmin ? (
        <Menu.Item key={menu.key}>
          {menu.icon ? <Icon type={menu?.icon} /> : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? (
            <Link onClick={() => closeMobileNav()} to={menu.path} />
          ) : null}
        </Menu.Item>
      ) : null;
    default:
      return null;
  }
};

const MenuContent = (props) => {
  const {
    worklist: { joblist },
    boat: { boatdetails },
  } = props;
  let dueTotal = 0;
  joblist &&
    joblist.length > 0 &&
    joblist.forEach((job) => (!job.status ? (dueTotal += 1) : null));
  const free = boatdetails && boatdetails.free;
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} dueTotal={dueTotal} free={free} />
  ) : (
    <TopNavContent {...props} dueTotal={dueTotal} free={free} />
  );
};

const mapStateToProps = (state) => ({
  sideNavTheme: state.theme.sideNavTheme,
  worklist: state.worklist,
  topNavColor: state.theme.topNavColor,
  user: state.auth.user,
  boat: state.boat,
  navCollapsed: state.theme.navCollapsed,
});

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
