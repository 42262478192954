import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// modification start - 10.23.2022 - maco-magsael-sc-604
import { Layout, Typography, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
// modification end - 10.23.2022 - maco-magsael-sc-604
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavSearch from './NavSearch';
import { onMobileNavToggle } from 'redux/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import utils from 'utils';
import NavPanel from './NavPanel';
import { updateNavCollapsed } from 'actions/theme';
import { updateEmail } from 'actions/user';
// import Notification from './Notification';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

/**
 */

const { Header } = Layout;
const { Title } = Typography;

export const HeaderNav = ({
  theme: {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    currentTheme,
    themeDetails,
  },
  auth: { user },
  onMobileNavToggle,
  isMobile,
  updateNavCollapsed,
  updateEmail,
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const boatName = user && user.boatName;

  const themeID = themeDetails && themeDetails._id;
  const userID = user && user._id;

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      updateNavCollapsed(!navCollapsed, userID, themeID);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff'
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            {!isMobile ? (
              <Title level={3} style={{ margin: '24px', color: '#ff5722' }}>
                {boatName}
              </Title>
            ) : null}

            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            <Menu mode="horizontal">
              <Menu.Item key="help">
                <a href={`${APP_PREFIX_PATH}/help`}>
                  <QuestionCircleOutlined className="nav-icon mr-0" />
                </a>
              </Menu.Item>
            </Menu>
            {/* <Notification /> */}
            <NavPanel />
            <NavProfile isMobile={isMobile} />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

HeaderNav.propTypes = {
  updateNavCollapsed: PropTypes.func.isRequired,
  onMobileNavToggle: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateNavCollapsed,
  onMobileNavToggle,
  updateEmail,
})(HeaderNav);
