import {
  SHOW_LITEPART_LOADING,
  GET_LITEPART,
  LITEPART_ERROR,
  EDIT_LITEPART,
  CREATE_LITEPART_SUCCESS,
  DELETE_LITEPART,
} from '../actions/types';

/**
 * olain-tarcina-sc-716: YMPLITE changes
 *
 */

const initialState = {
  litepartlist: [],
  litepartLoading: false,
  editLitepartLoading: false,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_LITEPART_LOADING:
      return {
        ...state,
        litepartLoading: true,
      };
    case GET_LITEPART:
      return {
        ...state,
        litepartlist: payload,
        litepartLoading: false,
      };
    case EDIT_LITEPART:
      return {
        ...state,
        litepartlist: state.litepartlist.map((liteparts) =>
          liteparts._id === payload._id
            ? {
                ...liteparts,
                start: payload.start,
                end: payload.end,
                allDay: payload.allDay,
              }
            : liteparts
        ),
        editLitepartLoading: false,
        litepartLoading: false,
      };
    case CREATE_LITEPART_SUCCESS:
      return {
        ...state,
        litepartlist: [payload, ...state.litepartlist],
        litepartLoading: false,
      };
    case LITEPART_ERROR:
      return {
        ...state,
        error: payload,
        litepartLoading: false,
      };
    case DELETE_LITEPART:
      return {
        ...state,
        litepartlist: state.litepartlist.filter(
          (liteparts) => liteparts._id !== payload
        ),
        litepartLoading: false,
      };
    default:
      return state;
  }
}
